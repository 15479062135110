import React from 'react';
import Layout from '../components/layout';

export default function NotFound() {
  return (
    <Layout>
      <h1>Not found</h1>
      <p>Page is not found, sorry.</p>
    </Layout>
  );
}
